// Alpine
import Alpine from "alpinejs";
import Plyr from "plyr";

// Extend webpack window classes

// Add to window
window.Plyr = Plyr;
window.Alpine = Alpine;
window.Alpine.start();
